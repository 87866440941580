import _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useMemo, useRef, useState, useCallback } from 'react'
import { useGetShiftsQuery, useLazyGetAccessRulesQuery, useGetAccessiblePodListQuery } from '@sevenrooms/core/api'
import type { AccessRule, Pod } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Select, type SelectOption } from '@sevenrooms/core/ui-kit/core'
import {
  MultiSelect,
  Button,
  SingleDatePicker,
  Label,
  MultiSelectTree,
  RadioGroup,
  type TreeNode,
  type RadioChoice,
} from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import {
  Grid,
  HStack,
  Banner,
  Box,
  DividerLine,
  Flex,
  Loader,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  SlideOut,
  Spreadsheet,
  VStack,
  Window,
  type DataTableColumn,
} from '@sevenrooms/core/ui-kit/layout'
import { Header, Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector, useVenueContext } from '@sevenrooms/mgr-core'
// eslint-disable-next-line import/no-relative-packages
import * as GlobalActions from '../../../application/site/static/app/manager/lib/actions/GlobalActions'
// eslint-disable-next-line import/no-relative-packages
import { store } from '../../../application/site/static/app/manager/pages/src/reduxStore'
import { accessRulesBulkCopyMessages } from './AccessRuleBulkCopy.locales'
import {
  fetchAccessRuleBulkCopyStatus,
  fetchValidationLogsOnAccessRuleCopy,
  postAccessRuleBulkCopy,
  type ValidationResponse,
} from './AccessRuleBulkCopyServices'
import { AccessRuleBulkCopySlideoutFooter } from './AccessRuleBulkCopySlideoutFooter'

export enum BulkCopyStatus {
  LOADING = 'LOADING',
  NOT_STARTED = 'NOT_STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface AccessRuleBulkCopyProps {
  bulkCopyTextElem?: HTMLElement
  onClose: () => void
  onStatusChange?: (status: BulkCopyStatus) => void
}

interface VenueInterface extends TreeNode {
  id: string
  value: string
  label: string
}

interface PodInterface extends TreeNode {
  id: string
  value: string
  label: string
  section: boolean
  children: VenueInterface[]
}

export function AccessRuleBulkCopy({ bulkCopyTextElem, onClose, onStatusChange }: AccessRuleBulkCopyProps) {
  const { formatMessage } = useLocales()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [date, onDateChange] = useState<Date | null>(null)
  const startDateStr = moment(date || new Date()).format('MMM DD')
  const {
    venue: { venueGroupId },
    venueId,
  } = useVenueContext()
  const [getAccessRules, { isLoading: accessRuleIsLoading, data: accessRuleData }] = useLazyGetAccessRulesQuery()
  const { data: shiftsData } = useGetShiftsQuery({ venueId })
  const isMobile = useMaxWidthBreakpoint('s')
  const userDomainVenues = useStoreSelector(state => state.appState.userDomain.venues)
  const { data } = useGetAccessiblePodListQuery({ venueGroupId })
  const pods = useMemo(() => data?.pods ?? [], [data?.pods])
  const venues = useMemo(() => data?.venues ?? [], [data?.venues])
  const userVenues = useMemo(
    () =>
      _.uniqBy(
        _.filter(userDomainVenues, v => v.venueGroupId === venueGroupId && v.isDiningClass),
        'id'
      ),
    [venueGroupId, userDomainVenues]
  )
  const venueSelectOptions: SelectOption[] = useMemo(() => _.map(userVenues, v => ({ id: v.id, label: v.name })), [userVenues])
  const targetTreeData = useMemo(() => {
    const venueTreeOptions: VenueInterface[] = _.map(userVenues, v => ({
      id: v.id,
      value: v.id,
      label: v.name,
    }))
    const podOptions: PodInterface[] = _.map(pods, p => ({
      id: p.id,
      value: p.id,
      label: p.name,
      section: true,
      children: venues
        .filter(i => p.venueIds.includes(i.id))
        .map(i => ({
          id: `${p.id}-${i.id}`,
          value: i.id,
          label: i.name,
        })),
    }))
    const podsToVenueOptions = _.filter(podOptions, p => p.children.length !== 0)
    const targetOptionsTree: { id: string; label: string; value: string; children: VenueInterface[] }[] = [
      { id: 'venues', label: formatMessage(accessRulesBulkCopyMessages.venues), value: 'venues', children: venueTreeOptions },
    ]
    if (podsToVenueOptions.length !== 0) {
      targetOptionsTree.unshift({
        id: 'pods',
        label: formatMessage(accessRulesBulkCopyMessages.pods),
        value: 'pods',
        children: podsToVenueOptions,
      })
    }
    return targetOptionsTree
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pods, userVenues, venues])
  const podIdsToVenueIds = getPodIdsToVenueIds(pods)
  const [selectedTargetIds, setSelectedTargetIds] = useState<string[]>([])
  const [selectedStrategy, setSelectedStrategy] = useState(1)
  const [selectedAccessRuleIds, setSelectedAccessRuleIds] = useState<string[]>([])
  const [sourceVenue, setSourceVenue] = useState(venueId)
  const [validationPhase, setValidationPhase] = useState({
    inProgress: false,
    ranOnce: false,
    targetFieldsSet: true,
    accessRulesSet: true,
    dateSet: true,
    page: 1,
    overrideCopyWithWarnings: '0',
    validationLogs: [] as ValidationResponse[],
  })
  const [isAccessRuleCustom, setIsAccessRuleCustom] = useState(false)
  const targetVenuesRef = useRef<HTMLSpanElement>(null)
  const startDateRef = useRef<HTMLSpanElement>(null)
  const accessRulesRef = useRef<HTMLDivElement>(null)
  const validationTableCols = useMemo<DataTableColumn<(typeof validationPhase.validationLogs)[number]>[]>(
    () => [
      {
        header: formatMessage(accessRulesBulkCopyMessages.warnings),
        headerAlign: 'start',
        cellAlign: 'start',
        render: 'message',
        sortable: false,
      },
      {
        header: formatMessage(accessRulesBulkCopyMessages.editSetting),
        headerAlign: 'end',
        cellAlign: 'end',
        key: 'action',
        render: (data: ValidationResponse) => (
          <Box textAlign="right">
            <a href={data.url || ''} target="_blank" rel="noreferrer">
              <Icon name={Icons['VMSWeb-open-in-new']} color="primaryIcons" />
            </a>
          </Box>
        ),
      },
    ],
    [formatMessage]
  )

  const accessRuleOptions = useMemo(() => {
    if (accessRuleData) {
      return [
        ...Object.values(accessRuleData)
          .flat()
          .reduce((map: Map<string, AccessRule>, currentValue: AccessRule) => {
            map.set(currentValue.id, currentValue)
            return map
          }, new Map())
          .values(),
      ].map(rule => {
        const days = rule.date ? rule.date.formatSWeek() : rule.dayOfWeekName?.join(', ')

        const dateRange = rule.date
          ? `${rule.date.formatNYearNMonthNDay()} - ${rule.date.formatNYearNMonthNDay()}`
          : `${rule.startDate?.formatNYearNMonthNDay()} - ${rule.endDate?.formatNYearNMonthNDay() || 'Indefinite'}`

        let time = rule.startEndTimesByShiftDisplay.flatMap((i: { times: string[] }) => i.times.join(' - ')).join(', ')
        if (rule.specificTimesDisplay?.length) {
          time = rule.specificTimesDisplay?.join(', ')
        } else if (rule.startTimeDisplay) {
          time = `${rule.startTimeDisplay} - ${rule.endTimeDisplay}`
        }

        const shiftCategories = rule.shiftCategories.length
          ? rule.shiftCategories.map((i: string) => i.toLowerCase()).join(', ')
          : `All (${shiftsData?.length})`
        const partySize = rule.partySizeMin
          ? `${rule.partySizeMin}-${rule.partySizeMax}`
          : shiftsData
              ?.filter(shift => {
                if (rule.shiftCategories?.length && shift.shiftCategory) {
                  return rule.shiftCategories?.includes(shift.shiftCategory)
                }
                return true
              })
              .map(shift => `${shift.minPartySize}-${shift.maxPartySize}`)
              .join(', ')

        return {
          id: rule.id,
          label: rule.name,
          description: (
            <Box pl="l" width="100%">
              <HStack>
                <VStack flex="1">
                  <Text fontSize="m" color="secondaryFont">
                    {formatMessage(accessRulesBulkCopyMessages.accessRuleSelectDays)}
                  </Text>
                </VStack>
                <VStack flex="3">
                  <Text fontSize="m" color="secondaryFont">
                    {days}
                  </Text>
                </VStack>
              </HStack>
              <HStack width="100%">
                <VStack flex="1" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {formatMessage(accessRulesBulkCopyMessages.accessRuleSelectDateRange)}
                  </Text>
                </VStack>
                <VStack flex="3" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {dateRange}
                  </Text>
                </VStack>
              </HStack>
              <HStack width="100%">
                <VStack flex="1" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {formatMessage(accessRulesBulkCopyMessages.accessRuleSelectTime)}
                  </Text>
                </VStack>
                <VStack flex="3" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {time}
                  </Text>
                </VStack>
              </HStack>
              <HStack width="100%">
                <VStack flex="1" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {formatMessage(accessRulesBulkCopyMessages.accessRuleSelectShiftCategories)}
                  </Text>
                </VStack>
                <VStack flex="3" mr="s">
                  <Text fontSize="m" color="secondaryFont" textTransform="capitalize">
                    {shiftCategories}
                  </Text>
                </VStack>
              </HStack>
              <HStack width="100%">
                <VStack flex="1" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {formatMessage(accessRulesBulkCopyMessages.accessRuleSelectPartySize)}
                  </Text>
                </VStack>
                <VStack flex="3" mr="s">
                  <Text fontSize="m" color="secondaryFont">
                    {partySize}
                  </Text>
                </VStack>
              </HStack>
            </Box>
          ),
        }
      })
    }
    return []
  }, [accessRuleData, formatMessage, shiftsData])

  const strategyOptions = useMemo(
    () => [
      {
        value: 1,
        label: `${formatMessage(accessRulesBulkCopyMessages.accessRuleSelectAll)} (${
          (!accessRuleIsLoading && accessRuleOptions?.length) || 0
        })`,
      },
      {
        value: 2,
        label: formatMessage(accessRulesBulkCopyMessages.accessRuleSelectCustom),
      },
    ],
    [accessRuleIsLoading, accessRuleOptions?.length, formatMessage]
  )

  const onStrategyChange = useCallback(
    (choice: RadioChoice<number>) => {
      setSelectedStrategy(choice.value)
      if (choice.value === 2) {
        // Select Access Rule(s) to add to existing rules in Target Venue(s)
        setIsAccessRuleCustom(true)
        setValidationPhase({ ...validationPhase, ranOnce: false })
      } else {
        // Replace all Access Rules
        setIsAccessRuleCustom(false)
        setValidationPhase({ ...validationPhase, ranOnce: false, accessRulesSet: true })
      }
    },
    [validationPhase]
  )

  const onAccessRuleIdsChange = useCallback(
    (selectedIds: string[]) => {
      setSelectedAccessRuleIds(selectedIds)
      setValidationPhase({ ...validationPhase, ranOnce: false, accessRulesSet: true })
    },
    [validationPhase]
  )

  const onDateChangeHandler = (d: Date | null) => {
    onDateChange(d)
    setValidationPhase({ ...validationPhase, dateSet: true, ranOnce: false })
    setSelectedAccessRuleIds([])
    if (d) {
      getAccessRules({ startDate: d.toISOString(), venueId: sourceVenue })
    }
  }

  const onVenueChange = (value: string) => {
    setSourceVenue(value)
    setValidationPhase({ ...validationPhase, ranOnce: false })
    if (date) {
      setSelectedAccessRuleIds([])
      getAccessRules({ startDate: date.toISOString(), venueId: value })
    }
  }

  const onTargetOptionsTreeChange = (_targetTree: TreeNode, selectedNodes: TreeNode[]) => {
    const selectedIds: string[] = []
    _.forEach(selectedNodes, node => {
      if (podIdsToVenueIds[node.value] !== undefined) {
        selectedIds.push(...(podIdsToVenueIds[node.value] || []))
      } else {
        selectedIds.push(node.value)
      }
    })
    setSelectedTargetIds(selectedIds)
    if (selectedIds.length !== 0) {
      setValidationPhase({ ...validationPhase, targetFieldsSet: true, ranOnce: false })
    }
  }

  function targetVenuesToIds(selectedTargetIds: string[]) {
    return [
      ...new Set(
        selectedTargetIds
          .map(
            venueId =>
              targetTreeData
                .find(targetData => targetData.id === venueId)
                ?.children.map(i => i?.children?.map(ii => ii.value) || i.value)
                .flat() || venueId
          )
          .flat()
      ),
    ]
  }

  const validateAccessRuleCopy = async () => {
    const targetVenues = targetVenuesToIds(selectedTargetIds)

    if (validationPhase.inProgress) {
      return
    }
    let targetFieldsSet = true
    let dateSet = true
    if (targetVenues.length === 0) {
      targetFieldsSet = false
    }
    if (date === null) {
      dateSet = false
    }
    if (!targetFieldsSet || !dateSet) {
      setValidationPhase({ ...validationPhase, targetFieldsSet, dateSet })
      if (!targetFieldsSet) {
        targetVenuesRef.current?.scrollIntoView()
      } else {
        startDateRef.current?.scrollIntoView()
      }
      return
    }

    if (selectedAccessRuleIds.length === 0 && selectedStrategy !== 1) {
      setValidationPhase({ ...validationPhase, accessRulesSet: false })
      accessRulesRef.current?.scrollIntoView()
      return
    }

    setValidationPhase({ ...validationPhase, validationLogs: [] as ValidationResponse[], inProgress: true, ranOnce: true, page: 1 })
    const startDate = moment(date).format('YYYY-MM-DD')
    const sourceVenueId = sourceVenue || ''
    const progress = targetVenues.map(targetVenueId =>
      fetchValidationLogsOnAccessRuleCopy(
        venueGroupId,
        sourceVenueId,
        targetVenueId,
        selectedStrategy === 2 ? selectedAccessRuleIds : [],
        startDate
      )
    )
    const venueValidationLogs = await Promise.all(progress)
    const flattenedLogs = venueValidationLogs.reduce((accumulator, value) => accumulator.concat(value), [])
    const validationLogs = flattenedLogs.filter(log => !!log)

    setValidationPhase({ ...validationPhase, validationLogs, inProgress: false, ranOnce: true, page: 1 })
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const $accessRuleBulkCopyBtn = document.getElementById('access-rule-bulk-copy-btn')!

  const proceedWithAccessRuleBulkCopy = async () => {
    const startDate = moment(date).format('YYYY-MM-DD')
    const sourceVenueId = sourceVenue || ''
    const targetVenues = targetVenuesToIds(selectedTargetIds)
    await postAccessRuleBulkCopy(
      venueGroupId,
      sourceVenueId,
      targetVenues,
      selectedAccessRuleIds,
      startDate,
      validationPhase.overrideCopyWithWarnings
    )

    setShowConfirmationModal(false)
    if ($accessRuleBulkCopyBtn instanceof HTMLButtonElement) {
      $accessRuleBulkCopyBtn.disabled = true
      $accessRuleBulkCopyBtn.style.color = '#CCC'
    }
    if (bulkCopyTextElem) {
      // eslint-disable-next-line no-param-reassign
      bulkCopyTextElem.innerHTML = formatMessage(accessRulesBulkCopyMessages.progressing)
    }
    if (onStatusChange) {
      onStatusChange(BulkCopyStatus.IN_PROGRESS)
    }
    pollBulkCopyStatus({
      venueGroupId,
      accessRuleBulkCopyBtn: $accessRuleBulkCopyBtn,
      accessRuleBulkCopyText: bulkCopyTextElem,
      onStatusChange,
    })
    onClose()
  }

  const startAccessRuleBulkCopy = () => {
    setShowConfirmationModal(true)
  }

  const onCopyWithWarningsChange = (choice: RadioChoice<string>) => {
    setValidationPhase({ ...validationPhase, overrideCopyWithWarnings: choice.value })
  }

  const renderValidationTable = () => {
    const overrideWarningChoices = [
      {
        value: '0',
        label: formatMessage(accessRulesBulkCopyMessages.overrideWarningChoicesSkip),
        description: formatMessage(accessRulesBulkCopyMessages.overrideWarningChoicesSkipDescription),
        disabled: false,
      },
      {
        value: '1',
        label: formatMessage(accessRulesBulkCopyMessages.overrideWarningChoicesContinue),
        description: formatMessage(accessRulesBulkCopyMessages.overrideWarningChoicesContinueDescription),
        disabled: false,
      },
    ] as RadioChoice<string>[]
    return (
      <Box>
        <Box>
          <Button data-test="re-run-validation-button" icon={Icons['VMSWeb-refresh']} variant="primary" onClick={validateAccessRuleCopy}>
            Re-run Validation
          </Button>
        </Box>
        <Box pt="m">
          <Spreadsheet data={validationPhase.validationLogs} columns={validationTableCols} height={480} />
        </Box>
        {validationPhase.validationLogs.length > 0 && (
          <Box pt="m">
            <Text>Copy/Don't copy warnings</Text>
            <Box pt="s">
              <RadioGroup
                name="copy_with_warnings"
                choices={overrideWarningChoices}
                onChange={onCopyWithWarningsChange}
                selected={validationPhase.overrideCopyWithWarnings}
              />
            </Box>
          </Box>
        )}
      </Box>
    )
  }
  const renderBannerDescription = useMemo(
    () => (
      <ul>
        <li>All recurring Access Rules in Target Venues that overlap with the selected date range will be set to end on {startDateStr}</li>
        <li>Any recurring Access Rules from the Source Venue within the selected date range will be copied to the Target Venue(s)</li>
        <li>
          The start date for the Access Rule(s) will be {startDateStr}, regardless of when the Access Rule starts for the Source Venue
        </li>
        <li>
          Any single-day Access Rule overrides from the Source Venue within the selected date range will be copied to the Target Venue(s)
        </li>
        {selectedStrategy === 1 && (
          <li>
            All Access Rules in the Target Venue(s) within the selected date range will be deleted and replaced with the Access Rules from
            the Source Venue
          </li>
        )}
        {selectedStrategy === 2 && (
          <>
            <li>
              All Access Rules in the Target Venue(s) within the selected date range that are not duplicates associated with the Access
              Rules from the Source Venue will be copied to the Target Venue(s)
            </li>
            <li>
              All Access Rules in the Target Venue(s) within the selected date range that are duplicates associated with the Access Rules
              from the Source Venue will be updated in the Target Venue(s)
            </li>
          </>
        )}
      </ul>
    ),
    [selectedStrategy, startDateStr]
  )

  let modalSubtitle
  if (validationPhase.overrideCopyWithWarnings === '1') {
    modalSubtitle = `You still have (${validationPhase.validationLogs.length}) warning(s) that have not been resolved.`
  }

  /* eslint-disable no-nested-ternary */
  return (
    <div>
      <SlideOut
        noTopPadding
        title="Access Rule Bulk Copy"
        show
        onCloseComplete={onClose}
        background="secondaryBackground"
        footer={<AccessRuleBulkCopySlideoutFooter />}
      >
        <Box pt="l">
          <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
            <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 6'}>
              <Label primary="Source Venue">
                <Select
                  value={sourceVenue}
                  options={venueSelectOptions}
                  onChange={onVenueChange}
                  contentProps={{ 'aria-label': 'Select Source Venue' }}
                  searchable={false}
                />
              </Label>
            </Box>
          </Grid>
        </Box>
        <Box pt="l">
          <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
            <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 6'}>
              <span ref={targetVenuesRef}>
                <Label primary="Target Venue(s)">
                  <MultiSelectTree
                    treeData={targetTreeData}
                    onChange={onTargetOptionsTreeChange}
                    invalid={!validationPhase.targetFieldsSet}
                    id="access-rule-target-venues"
                  />
                </Label>
              </span>
            </Box>
          </Grid>
        </Box>
        <Box pt="l">
          <Header type="h3">Schedule</Header>
        </Box>
        <Box pt="s">
          <Text>{formatMessage(accessRulesBulkCopyMessages.schedule)}</Text>
        </Box>
        <Box pt="lm">
          <span ref={startDateRef}>
            <Label primary="Start date">
              <SingleDatePicker
                id="access-rule-start-date"
                value={date}
                onChange={onDateChangeHandler}
                invalid={!validationPhase.dateSet}
                showClearDate
              />
            </Label>
          </span>
        </Box>
        <Box pt="l">
          <Box mb="s">
            <Text fontSize="m">{formatMessage(accessRulesBulkCopyMessages.accessRuleSelect)}</Text>
          </Box>
          <RadioGroup
            name="strategy"
            choices={strategyOptions}
            onChange={onStrategyChange}
            selected={selectedStrategy}
            disabled={!date && accessRuleIsLoading}
          />
          {isAccessRuleCustom && (
            <Box mt="s" ref={accessRulesRef}>
              <MultiSelect
                options={accessRuleOptions}
                onChange={onAccessRuleIdsChange}
                initialSelectedIds={selectedAccessRuleIds}
                invalid={!validationPhase.accessRulesSet}
                disabled={!date}
                searchable
                useCheckboxItems
                wrapText
              />
            </Box>
          )}
        </Box>
        <Box mt="lm">
          <Banner title={formatMessage(accessRulesBulkCopyMessages.title)} type="success" description={renderBannerDescription} />
        </Box>
        <Box pt="l">
          <DividerLine />
        </Box>
        <Box mt="l" pb="m">
          <Header type="h3">Step 1</Header>
          <Box pt="s" width="600px">
            <Text>
              Once validation is complete you may see warnings or conflicts between Access Rules across Venues. It is highly recommended
              that warnings/conflicts are solved before continuing to step 2.
            </Text>
          </Box>
          <Box pt="m">
            {validationPhase.inProgress
              ? renderLoader()
              : !validationPhase.inProgress && validationPhase.ranOnce
              ? validationPhase.validationLogs.length === 0
                ? renderValidationSuccess()
                : renderValidationTable()
              : renderValidateButton(validateAccessRuleCopy)}
          </Box>
        </Box>
        <Box>
          <DividerLine />
        </Box>
        <Box pt="l" pb="m">
          <Header type="h3">Step 2</Header>
          <Box pt="s" width="600px">
            <Text>This step will copy all Access Rules and, depending on decision above, will/will not copy those over with warnings.</Text>
          </Box>
          <Box pt="m">
            <Button
              data-test="clone-to-venues-button"
              variant="primary"
              disabled={!validationPhase.ranOnce || validationPhase.inProgress}
              onClick={startAccessRuleBulkCopy}
            >
              Clone to Venues
            </Button>
          </Box>
        </Box>
      </SlideOut>
      <Window active={showConfirmationModal}>
        <Modal ariaLabel="Modal">
          <ModalHeader
            onClose={() => {
              setShowConfirmationModal(false)
            }}
          >
            <VStack spacing="s">
              <Status kind="warning">Warning</Status>
              <ModalTitle title="This action cannot be undone" subTitle={modalSubtitle} />
            </VStack>
          </ModalHeader>
          <ModalBody>
            <Text>
              Cloning can take time depending on how many Access Rules and Venues are processing. We will email you once the clone is
              complete.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <Button
                data-test="go-back-button"
                variant="secondary"
                onClick={() => {
                  setShowConfirmationModal(false)
                }}
              >
                Go Back
              </Button>
              <Button data-test="proceed-with-clone-button" variant="primary" onClick={proceedWithAccessRuleBulkCopy}>
                Proceed with Clone
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>
    </div>
  )
  /* eslint-enable no-nested-ternary */
}

const getPodIdsToVenueIds = (podsToVenues: Pod[]) => {
  const podIdsToVenues: { [k: string]: string[] } = {}
  for (const pod of podsToVenues) {
    podIdsToVenues[pod.id] = pod.venueIds
  }
  return podIdsToVenues
}

const renderValidateButton = (validateAccessRuleCopy: () => void) => (
  <Button data-test="validate-button" variant="primary" onClick={validateAccessRuleCopy}>
    Validate
  </Button>
)

const renderLoader = () => <Loader>Validating Venue Access Rule(s). This may take some time depending on the amount of Venues.</Loader>

const renderValidationSuccess = () => (
  <Box textAlign="center">
    <Icon name={Icons['VMSWeb-check']} />
    <Box pt="s">
      <Flex justifyContent="center">
        <Text>Great news! No warnings were found so all Access Rules will be</Text>
      </Flex>
      <Flex justifyContent="center">
        <Text>copied across to Target Venues.</Text>
      </Flex>
    </Box>
  </Box>
)

interface PollBulkCopyStatusParams {
  venueGroupId: string
  accessRuleBulkCopyBtn?: HTMLElement
  accessRuleBulkCopyText?: HTMLElement
  onStatusChange?: (status: BulkCopyStatus) => void
  onComplete?: () => void
  previousStatus?: BulkCopyStatus
}

async function doPollBulkCopyStatus({
  venueGroupId,
  accessRuleBulkCopyBtn,
  accessRuleBulkCopyText,
  onStatusChange,
  onComplete,
  previousStatus,
}: PollBulkCopyStatusParams) {
  const response = await fetchAccessRuleBulkCopyStatus(venueGroupId)
  if (!previousStatus || previousStatus !== response.status) {
    if (response.status === 'COMPLETED') {
      store.dispatch(GlobalActions.showSuccessMessage('Access rule clone complete'))
    } else if (response.status === 'FAILED') {
      store.dispatch(GlobalActions.showErrorMessage('Access rule clone failed'))
    }
  }
  if (onStatusChange) {
    onStatusChange(response.status as BulkCopyStatus)
  }
  if (response.status === 'COMPLETED' || response.status === 'FAILED') {
    if (accessRuleBulkCopyBtn instanceof HTMLButtonElement) {
      // eslint-disable-next-line no-param-reassign
      accessRuleBulkCopyBtn.disabled = false
      // eslint-disable-next-line no-param-reassign
      accessRuleBulkCopyBtn.style.color = '#347baf'
    }
    if (accessRuleBulkCopyText) {
      // eslint-disable-next-line no-param-reassign
      accessRuleBulkCopyText.innerHTML = 'Bulk Copy'
    }
    if (onComplete) {
      onComplete()
    }
  }
}

export async function pollBulkCopyStatus(params: Omit<PollBulkCopyStatusParams, 'onComplete'>) {
  await doPollBulkCopyStatus(params)
  const intervalId = setInterval(async () => {
    doPollBulkCopyStatus({ ...params, onComplete: () => clearInterval(intervalId) })
  }, 30000)
}
