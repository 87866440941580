import * as ReactDOM from 'react-dom'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { AccessRuleBulkCopy, pollBulkCopyStatus } from './AccessRuleBulkCopy'

const render = (elem: HTMLElement, bulkCopyTextElem: HTMLElement) => {
  ReactDOM.render(
    <AppRoot>
      <AccessRuleBulkCopy bulkCopyTextElem={bulkCopyTextElem} onClose={() => elem && ReactDOM.unmountComponentAtNode(elem)} />
    </AppRoot>,
    elem
  )
}

export const startBulkCopyStatusPoll = (venueGroupId: string, accessRuleBulkCopyBtn: HTMLElement, accessRuleBulkCopyText: HTMLElement) => {
  pollBulkCopyStatus({ venueGroupId, accessRuleBulkCopyBtn, accessRuleBulkCopyText })
}

svrExport('MgrAccessRuleBulkCopy', 'render', render)
svrExport('MgrAccessRuleBulkCopy', 'startBulkCopyStatusPoll', startBulkCopyStatusPoll)
